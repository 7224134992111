export default [
  {
    'id': 1,
    'label': 'day.monday',
  },
  {
    'id': 2,
    'label': 'day.tuesday',
  },
  {
    'id': 3,
    'label': 'day.wednesday',
  },
  {
    'id': 4,
    'label': 'day.thursday',
  },
  {
    'id': 5,
    'label': 'day.friday',
  },
  {
    'id': 6,
    'label': 'day.saturday',
  },
  {
    'id': 7,
    'label': 'day.sunday',
  },
];
