<template>
  <div class="working-schedule">
    <b-row align-h="between" align-v="center" class="mb-1">
      <b-col>
        <label> {{ $t("field.schedule") }} </label>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="outline-primary"
          pill
          class="btn-plus"
          @click="addNewLine"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
    </b-row>
    <div
      v-for="(line, idx) in lines"
      :key="`schedule-line-${idx}-${lines.length}`"
      class="line"
      :class="{
        'mb-2': idx < lines.length - 1,
      }"
    >
      <b-row align-h="end" v-if="lines.length > 1">
        <b-col cols="auto">
          <b-button
            variant="outline-danger"
            pill
            class="btn-close"
            @click="removeLine(idx)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            :vid="`nameEn-${idx}`"
            name="field.nameEn"
            rules="required"
          >
            <b-form-group>
              <label> {{ $t("field.nameEn") }} </label>
              <b-form-input
                v-model="line.nameEn"
                :ref="`nameEn-${idx}`"
                :name="`nameEn-${idx}`"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="input"
              >
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            :vid="`nameKm-${idx}`"
            name="field.nameKm"
            rules="required"
          >
            <b-form-group>
              <label> {{ $t("field.nameKm") }} </label>
              <b-form-input
                v-model="line.nameKm"
                :ref="`nameKm-${idx}`"
                :name="`nameKm-${idx}`"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="input"
              >
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            :vid="`leaveAmount-${idx}`"
            name="field.leaveAmount"
            rules="required|max_value:1|decimal:2"
          >
            <b-form-group>
              <label> {{ $t("field.leaveAmount") }} </label>
              <b-form-input
                v-model="line.leaveAmount"
                :ref="`leaveAmount-${idx}`"
                :name="`leaveAmount-${idx}`"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="input"
              >
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            :vid="`day-${idx}`"
            name="field.day"
            rules="required"
          >
            <b-form-group>
              <label> {{ $t("field.day") }} </label>
              <n-single-select
                v-model="line.day"
                :options="days"
                :ref="`day-${idx}`"
                :name="`day-${idx}`"
                selection-key="id"
                selection-label="label"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="input"
              >
              </n-single-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            :vid="`clockin-${idx}`"
            name="field.clockInTime"
            rules="required"
          >
            <b-form-group>
              <label> {{ $t("field.clockInTime") }} </label>
              <n-time-picker
                v-model="line.clockIn"
                :ref="`clockin-${idx}`"
                :name="`clockin-${idx}`"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="input"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            :vid="`clockout-${idx}`"
            name="field.clockOutTime"
            :rules="`required`"
          >
            <b-form-group>
              <label> {{ $t("field.clockOutTime") }} </label>
              <n-time-picker
                v-model="line.clockOut"
                :ref="`clockout-${idx}`"
                :name="`clockout-${idx}`"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="input"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-1">
      <b-col class="text-right">
        <b-button variant="outline-primary" @click="addNewLine">
          <feather-icon icon="PlusIcon" />
          {{ $t("button.addSchedule") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BLink,
  BButton,
} from "bootstrap-vue";
import NSingleSelect from "@/components/NSingleSelect.vue";
import NTimePicker from "@/components/NTimePicker.vue";
import days from "@/data/days";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BLink,
    BButton,

    NSingleSelect,
    NTimePicker,
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    days() {
      return days.map((element) => {
        return {
          ...element,
          label: this.$t(element.label),
        };
      });
    },
  },
  data() {
    return {
      lines: [],
    };
  },
  mounted() {
    this.lines = [...this.value];
  },
  methods: {
    addNewLine() {
      this.lines.push({
        day: null,
        clockIn: null,
        clockOut: null,
      });
      this.input();
    },
    removeLine(idx) {
      this.lines.splice(idx, 1);
      this.input();
    },
    input() {
      this.$emit("input", this.lines);
    },
  },
};
</script>