export default [
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'minVisitMinute',
    label: 'field.minVisitMinute',
    rules: 'required|integer',
    type: 'currency',
    suffix: 'time.minute',
    suffixLocalize: true,
  },
  {
    key: 'maxVisitMinute',
    label: 'field.maxVisitMinute',
    rules: 'required|integer',
    type: 'currency',
    suffix: 'time.minute',
    suffixLocalize: true,
  },
  {
    key: 'description',
    label: 'field.description',
    rules: 'required|max:300',
    type: 'textarea',
  },
  {
    key: 'lines',
    label: 'field.schedule',
    cols: 12,
  },
]
